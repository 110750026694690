import React, { useCallback, useEffect, useMemo, useState } from "react"
import { animated, useSpring, useTransition } from "react-spring"
import cn from "classnames"
import throttle from "lodash/throttle"
import RefreshIcon from "@/assets/icons/refresh.inline.svg"
import ArrowDownIcon from "@/assets/icons/arrow-down.inline.svg"
import ClientOnly from "@/util/ClientOnly"
import loadable from "@loadable/component"
import * as style from "./index.modules.scss"
import { useLocomotiveScroll } from "@/util/locomotiveScroll/hooks"
// import GravityStackLazy from "./gravityStack"
const GravityStackLazy = loadable(() => import("./gravityStack"))

const isSvg = /\.svg$/

type ImageFile = { file: { url: string } }
type AntonProps = {
  h1: string
  scrollTriggerText: string
  phrases: { text: string; image: ImageFile; imageBounds: ImageFile }[]
}
export default function Anton({ h1, phrases, scrollTriggerText }: AntonProps) {
  const [phraseIndex, setPhraseIndex] = useState(0)
  const locomotiveScroll = useLocomotiveScroll()

  const sprites = useMemo(() => {
    return phrases.reduce((acc, curr) => {
      if (
        curr?.imageBounds?.file?.url &&
        isSvg.test(curr?.imageBounds?.file?.url) &&
        curr?.image?.file?.url
      ) {
        acc.push([curr.imageBounds.file.url, curr.image.file.url])
      }
      return acc
    }, [] as [string, string][])
  }, [phrases])

  useEffect(() => {
    sprites.forEach(([imageSrc, svgSrc], i) => {
      /** первая картинка загрузится сама */
      if (i > 0) {
        fetch(imageSrc)
        fetch(svgSrc)
      }
    })
  }, [])

  const currentText = phrases?.[phraseIndex]?.text

  const [rotateSpring, setRotateSpring] = useSpring(() => ({}))
  const transitions = useTransition(currentText, null, {
    from: { transform: "translate3d(0,-60px,0)" },
    enter: { transform: "translate3d(0,0,0)" },
    leave: { transform: "translate3d(0,60px,0)", position: "absolute" },
  })

  const handleRefresh = useCallback(
    throttle(() => {
      const nextIndex = (phraseIndex + 1) % phrases.length

      setPhraseIndex(nextIndex)
      setRotateSpring({
        from: { transform: "rotate(0deg)" },
        // @ts-ignore
        to: { transform: "rotate(-180deg)" },
        reset: true,
      })
    }, 100),
    [phrases, phraseIndex]
  )

  const handleArrowClick = useCallback(() => {
    const $target = document.querySelector("#projects")

    if ($target && locomotiveScroll) {
      locomotiveScroll.scrollTo($target)
    }
  }, [locomotiveScroll])

  return (
    <div className={cn(style.root, "px-3 desktop:px-7 relative")}>
      <div
        className={cn(
          style.strip,
          "desktop:flex w-full desktop:w-4/5 items-center overflow-hidden"
        )}
      >
        {h1 && <span className={"text-x8 desktop:text-x1"}>{h1}</span>}
        <animated.button
          onClick={handleRefresh}
          style={rotateSpring}
          className={cn("mx-4", style.refreshButton)}
        >
          <RefreshIcon className={style.refreshIcon} />
        </animated.button>
        <div
          className={
            "relative h-full flex flex-auto items-center text-x6s desktop:text-x1s overflow-hidden desktop:overflow-visible"
          }
        >
          {transitions.map(({ item, key, props }) => {
            return (
              <animated.div
                key={key}
                style={props}
                className={"whitespace-no-wrap"}
              >
                {item}
              </animated.div>
            )
          })}
        </div>
      </div>

      <ClientOnly
        placeholder={
          <div
            className={"absolute bottom-0 left-0 w-full h-full bg-light-gray"}
          />
        }
      >
        <GravityStackLazy
          className={cn(
            "absolute bottom-0 left-0 w-full overflow-hidden",
            style.gravityStack
          )}
          sprites={sprites}
          cursor={phraseIndex}
        />
      </ClientOnly>

      <button
        onClick={handleArrowClick}
        className={
          "text-x6s desktop:text-x1s flex justify-center absolute bottom-0 right-0 px-3 desktop:px-7 py-4 w-full desktop:w-auto"
        }
      >
        <ArrowDownIcon className={"mr-3"} /> {scrollTriggerText}
      </button>
    </div>
  )
}
