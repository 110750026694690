import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import Link from "@/components/ani-link"
import Img from "@/lib/gatsby-image"
import ViewIcon from "@/assets/icons/view.inline.svg"
import cn from "classnames"
import ContrastText from "@/util/ContrastText"
import typograf from "@/util/typograf"
import * as style from "./big.modules.scss"
export default function ProjectPreviewBig({
  title,
  slug,
  shortDescription,
  bigCover,
  coverColor,
  bigCoverAsABackground,
  shortDescriptionColor,
}) {
  const coverProps = React.useMemo(() => {
    return (
      bigCover && {
        fixed: bigCover.fixed,
        style: {
          ...(bigCover.style || {}),
          maxWidth: bigCover.file.details.image.width,
          maxHeight: bigCover.file.details.image.height,
          height: "100%",
          width: "100%",
          margin: "0 auto",
        },
        imgStyle: {
          objectFit: "contain",
        },
      }
    )
  }, [bigCover])

  const image = bigCover
  const footerBackgroundColor = shortDescriptionColor || coverColor || "#fff"
  const shouldUseBackgroundImage = bigCoverAsABackground

  const imgBackgroundRef = React.useRef()

  React.useEffect(() => {
    let io = null

    if (shouldUseBackgroundImage && imgBackgroundRef.current) {
      io = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            io.unobserve(entry.target)
            entry.target.style.opacity = 1
          }
        },
        { rootMargin: `0px` }
      )

      io.observe(imgBackgroundRef.current)
    }

    return () => {
      if (io) {
        io.unobserve(imgBackgroundRef.current)
      }
    }
  }, [shouldUseBackgroundImage])

  return (
    <Link to={`/${slug}/`} className={"block"} data-aos="fade-in">
      <div
        className={cn(
          style.cover,
          "flex items-center justify-center overflow-hidden"
        )}
        style={{
          backgroundColor: coverColor || "#1a1a1a",
          padding: shouldUseBackgroundImage ? "0 !important" : undefined,
        }}
      >
        {shouldUseBackgroundImage && image && (
          <div
            ref={imgBackgroundRef}
            className={style.coverImageAsBackground}
            style={{
              backgroundImage:
                shouldUseBackgroundImage && image
                  ? `url(${image.fluid.src})`
                  : undefined,
              opacity: 0,
            }}
          />
        )}
        {!shouldUseBackgroundImage && image && (
          <div className={cn("hidden desktop:block w-auto h-full")}>
            <Img {...coverProps} />
          </div>
        )}
        {!shouldUseBackgroundImage && image && (
          <div className={cn("w-full h-auto desktop:hidden")}>
            <Img fluid={image.fluid} className={""} />
          </div>
        )}
      </div>
      <ContrastText
        backgroundColor={footerBackgroundColor}
        className={cn("pt-4 pb-5 text-center px-3", style.footer)}
      >
        <div className={"text-x6s desktop:text-x3s desktop:leading-none"}>
          {title.title}
        </div>
        <div className={"text-x6 desktop:text-x3 desktop:leading-none"}>
          {typograf(shortDescription.shortDescription)}
        </div>

        <div
          className={cn(
            style.footerHoverBlock,
            "text-x6s desktop:text-x3s flex items-center justify-center"
          )}
          style={{ backgroundColor: footerBackgroundColor }}
        >
          <ViewIcon />
        </div>
      </ContrastText>
    </Link>
  )
}

ProjectPreviewBig.propTypes = {
  title: PropTypes.shape({
    title: PropTypes.string,
  }),
  slug: PropTypes.string,
  shortDescription: PropTypes.shape({
    shortDescription: PropTypes.string,
  }),
  shortDescriptionColor: PropTypes.string,
  bigCover: PropTypes.object,
  bigCoverAsABackground: PropTypes.bool,
  coverColor: PropTypes.string,
}
